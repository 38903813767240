import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignUpModal from "../components/Modals/SignUpModal";
import ErrorText from "../components/Typography/ErrorText";

function ResetPassword() {
  const INITIAL_OBJ = {
    password: "",
    confirmPassword: "",
  }

  const [showRegModal, setShowRegModal] = useState(false);
  const [formData, setFormData] = useState(INITIAL_OBJ);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const navigate = useNavigate();
  const showPasswordHandler = (type) => {
    if (type === 'p')
      setShowPassword(!showPassword);
    else
      setConfirmShowPassword(!showConfirmPassword);
  }
  const handleOnClose = () => {
    setShowRegModal(false);
    navigate('/login');
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required *";
    } else if (formData.password.length < 6) {
      validationErrors.password = "Password should be at least 6 char";
    }

    if (formData.confirmPassword !== formData.password) {
      validationErrors.confirmPassword = "Password not matched";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const params = new URLSearchParams(location.search);
      console.log(params);
      try {
        const response = await fetch('https://api.citiwave.io/auth/reset-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: params.get("token"),
            newPassword: formData.password
          })
        });

        const data = await response.json();
        if (response.status === 200) {
          // Redirect the user to a page that only displays the success message.
          setShowRegModal(true);
        } else if (response.status === 400) {
          setErrorMessage(data.message)
        } else if (response.status === 500) {
          setErrorMessage("Something went wrong");
        } else {
          // Handle any other unexpected responses.
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      } catch (error) {
        setErrorMessage('An unexpected error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {/* RESET-PASSWORD START */}
      <div className="authentication">
        <figure className="auth-thumbnail">
          <img
            src="/auth-thumbnail.png"
            alt="auth-thumbnail"
            className="w-full"
          />
          <div className="absolute inset-0 z-20 h-full flex justify-center items-center overlay">
            <div className="flex flex-col gap-2 text-center">
              <h4 className="text-white text-5xl font-sans font-bold">
                Reset password
              </h4>

              <div className="authenticinfo">
                <p className="text-white text-2xl font-sans font-normal">
                  Please choose your new password
                </p>
              </div>
            </div>
          </div>
        </figure>

        <div className="py-14 flex justify-center rightcontent">
          <div className="authinfo w-full">
            <div className="flex flex-col w-full overflow-hidden">
              <div className="flex justify-center">
                <figure className="brandlogo">
                  <img src="/logo.svg" alt="brand-logo" />
                </figure>
              </div>

              <form onSubmit={handleSubmit} className="authform">

                <div className="authform-body">

                  <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
                  <div className="forminfo">
                    <div className="passwordbox">
                      <div className="passbox">
                        <div className="inputinfo">
                          <label htmlFor="resetpass" className="inputlabel">
                            Password
                          </label>
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="resetpass"
                            className="inputfield"
                            name="password"
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        {errors.password && (
                          <p className="errortext">{errors.password}</p>
                        )}
                      </div>

                      <button className="btn-showhide" type='button' onClick={() => showPasswordHandler('p')}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M8.00008 3C4.66675 3 1.82008 5.07333 0.666748 8C1.82008 10.9267 4.66675 13 8.00008 13C11.3334 13 14.1801 10.9267 15.3334 8C14.1801 5.07333 11.3334 3 8.00008 3ZM8.00008 11.3333C6.16008 11.3333 4.66675 9.84 4.66675 8C4.66675 6.16 6.16008 4.66667 8.00008 4.66667C9.84008 4.66667 11.3334 6.16 11.3334 8C11.3334 9.84 9.84008 11.3333 8.00008 11.3333ZM8.00008 6C6.89341 6 6.00008 6.89333 6.00008 8C6.00008 9.10667 6.89341 10 8.00008 10C9.10675 10 10.0001 9.10667 10.0001 8C10.0001 6.89333 9.10675 6 8.00008 6Z"
                              fill="#616161"
                            />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    <div className="passwordbox">
                      <div className="passbox">
                        <div className="inputinfo">
                          <label htmlFor="conresetpass" className="inputlabel">
                            Confirm Password
                          </label>
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="conresetpass"
                            className="inputfield"
                            placeholder="Re-enter your password."
                            name="confirmPassword"
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="errormessage">
                          <figure className="erroricon">
                            <svg
                              width="85"
                              height="8"
                              viewBox="0 0 85 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="85"
                                height="8"
                                rx="4"
                                fill="#F52828"
                              />
                            </svg>
                          </figure>

                          {errors.confirmPassword && (
                            <p className="errortext">
                              {errors.confirmPassword}
                            </p>
                          )}
                        </div>
                      </div>

                      <button className="btn-showhide" type='button' onClick={() => showPasswordHandler('cp')}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M8.00008 3C4.66675 3 1.82008 5.07333 0.666748 8C1.82008 10.9267 4.66675 13 8.00008 13C11.3334 13 14.1801 10.9267 15.3334 8C14.1801 5.07333 11.3334 3 8.00008 3ZM8.00008 11.3333C6.16008 11.3333 4.66675 9.84 4.66675 8C4.66675 6.16 6.16008 4.66667 8.00008 4.66667C9.84008 4.66667 11.3334 6.16 11.3334 8C11.3334 9.84 9.84008 11.3333 8.00008 11.3333ZM8.00008 6C6.89341 6 6.00008 6.89333 6.00008 8C6.00008 9.10667 6.89341 10 8.00008 10C9.10675 10 10.0001 9.10667 10.0001 8C10.0001 6.89333 9.10675 6 8.00008 6Z"
                              fill="#616161"
                            />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    <div className="submitbox">
                      <button type="submit" className="btn-submit" disabled={loading}>
                        {loading ? 'loading' : 'Reset Password'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* RESET-PASSWORD END */}

      {/* RESETPASSWORD-MODAL START */}
      <SignUpModal onClose={handleOnClose} visible={showRegModal}>
        <div className="modalcontent">
          <div className="modalcontent-header">
            <figure className="modalicon">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                    fill="#32BA7C"
                  />
                  <path
                    d="M29.7798 58.044L50.3476 78.6118C67.3823 74.0692 80.0006 58.5487 80.0006 39.9998V38.8642L63.8492 23.9746L29.7798 58.044Z"
                    fill="#0AA06E"
                  />
                  <path
                    d="M41.0099 48.9588C42.7765 50.7253 42.7765 53.7537 41.0099 55.5203L37.3506 59.1796C35.5841 60.9461 32.5557 60.9461 30.7891 59.1796L14.7639 43.0282C12.9973 41.2616 12.9973 38.2332 14.7639 36.4666L18.4232 32.8073C20.1898 31.0408 23.2181 31.0408 24.9847 32.8073L41.0099 48.9588Z"
                    fill="white"
                  />
                  <path
                    d="M55.0163 21.0725C56.7829 19.3059 59.8113 19.3059 61.5778 21.0725L65.2371 24.7318C67.0037 26.4983 67.0037 29.5267 65.2371 31.2933L37.4769 58.9274C35.7103 60.6939 32.6819 60.6939 30.9154 58.9274L27.2561 55.2681C25.4895 53.5015 25.4895 50.4731 27.2561 48.7065L55.0163 21.0725Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath>
                    <rect width="80" height="80" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </figure>
          </div>

          <div className="modalcontent-body">
            <h5 className="modaltitle">Reset Successfully</h5>
            <div className="info">
              <p>
                Your password has been reset, You may now login.{" "}
                <Link to="/login" className="forgotlink" onClick={handleOnClose}>
                  Go to Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </SignUpModal>
      {/* RESETPASSWORD-MODAL END */}
    </>
  );
}

export default ResetPassword;
