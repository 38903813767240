import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./msalConfig";
import { Singup } from "./pages/register-1";

// NEW-PAGES
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgotPass from "./pages/ForgotPass";
import ResetPassword from "./pages/ResetPassword";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
// const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
// const token = checkAuth();

function App() {

  useEffect(() => {
    themeChange(false);

    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "light");
      document.documentElement.setAttribute("data-theme", "light");
    }

    // Check for serviceToken cookie
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('serviceToken='))
      ?.split('=')[1];

    if (token) {
      // Redirect to the platform URL if token exists
      window.location.href = 'https://platform.citiwave.io/apps';
    }
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <>
        <Router>
          <Routes>
            <Route path="/register" element={<SignUp />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/*<Route path="/login" element={<Login />} />*/}
            {/*<Route path="/register-1" element={<Singup />} />*/}
            {/*<Route path="/forgot-password" element={<ForgotPassword />} />*/}
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/documentation" element={<Documentation />} />

            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />

            <Route
              path="*"
              element={
                <Navigate to="/login" />
              }
            />
          </Routes>
        </Router>
      </>
    </MsalProvider>
  );
}

export default App;
