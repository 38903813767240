import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignUpModal from "../components/Modals/SignUpModal";
import ErrorText from "../components/Typography/ErrorText";

function ForgotPassword() {
  const INITIAL_OBJ = {
    email: ""
  }

  const [showRegModal, setShowRegModal] = useState(false);
  const handleOnClose = () => setShowRegModal(false);

  const [formData, setFormData] = useState(INITIAL_OBJ);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required *";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is not valid";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await fetch('https://api.citiwave.io/auth/forgot-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        const data = await response.json();
        if (response.status === 200) {
          // Redirect the user to a page that only displays the success message.
          setShowRegModal(true);
        } else if (response.status === 400) {
          setShowRegModal(true);
        } else if (response.status === 500) {
          setErrorMessage("Something went wrong");
        } else {
          // Handle any other unexpected responses.
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      } catch (error) {
        setErrorMessage('An unexpected error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {/* FORGOT-PASSWORD START */}
      <div className="authentication">
        <figure className="auth-thumbnail">
          <img
            src="/auth-thumbnail.png"
            alt="auth-thumbnail"
            className="w-full"
          />
          <div className="absolute inset-0 z-20 h-full flex justify-center items-center overlay">
            <div className="flex flex-col gap-2 text-center">
              <h4 className="text-white text-5xl font-sans font-bold">
                Forgot password?
              </h4>

              <div className="authenticinfo">
                <p className="text-white text-2xl font-sans font-normal">
                  Enter your registered email address and we'll send you
                  a password reset link.
                </p>
              </div>
            </div>
          </div>
        </figure>

        <div className="py-14 flex justify-center rightcontent">
          <div className="authinfo w-full">
            <div className="flex flex-col w-full">
              <div className="flex justify-center">
                <figure className="brandlogo">
                  <img src="/logo.svg" alt="brand-logo" />
                </figure>
              </div>

              <form onSubmit={handleSubmit} className="authform">
                <h3 className="authtitle">
                  {/* Create account with email address */}
                  Enter the email address associated with your account to reset your password.
                </h3>
                <div className="authform-body">
                  <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
                  <div className="forminfo">
                    <div className="inputbox">
                      <div className="inputinfo">
                        <label htmlFor="forgotmail" className="inputlabel">
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="forgotmail"
                          className="inputfield"
                          name="email"
                          autoComplete="off"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.email && (
                        <p className="errortext">{errors.email}</p>
                      )}
                    </div>

                    <div className="submitbox">
                      <button type="submit" className="btn-submit" disabled={loading}>
                        {loading ? 'loading..' : 'Send'}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="authform-footer">
                  <p className="textauth">
                    Remember Password?
                    <Link to="/login" className="forgotlink">
                      Sign in
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* FORGOT-PASSWORD END */}

      {/* FORGOTPASSWORD-MODAL START */}
      <SignUpModal onClose={handleOnClose} visible={showRegModal}>
        <div className="modalcontent">
          <div className="modalcontent-header">
            <figure className="modalicon">
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M80 27.7256L40 53.145L0 27.7256L40 1.66113L80 27.7256Z"
                    fill="#4C5254"
                  />
                  <path d="M40 1.66113L80 27.7256L40 53.145" fill="#2F423F" />
                  <path
                    d="M17.0324 9.66113C15.2259 9.66113 12.9033 11.7256 12.9033 13.5321V76.2418C12.9033 78.0482 15.2259 79.3386 17.0324 79.3386H62.9678C64.7743 79.3386 65.8065 78.0482 65.8065 76.2418V23.7256L51.613 9.66113H17.0324Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M65.8065 76.7579V23.7256L51.613 9.66113H17.0324C15.2259 9.66113 12.9033 11.7256 12.9033 13.5321"
                    fill="#D6D6D6"
                  />
                  <path
                    d="M65.8063 35.2093V23.8545L58.8385 16.8867L53.1611 22.8222L65.8063 35.2093Z"
                    fill="#A8A8A8"
                  />
                  <path
                    d="M51.6128 20.3708V9.66113L65.8063 23.8547H54.9676C53.0321 23.8547 51.6128 22.1773 51.6128 20.3708Z"
                    fill="white"
                  />
                  <path
                    d="M43.9998 22.5642C43.9998 23.3384 43.3547 23.8546 42.7095 23.8546H22.9676C22.1934 23.8546 21.6772 23.3384 21.6772 22.5642C21.6772 21.7901 22.3224 21.2739 22.9676 21.2739H42.5805C43.3547 21.2739 43.9998 21.7901 43.9998 22.5642ZM58.3224 32.8868C58.3224 33.661 57.6772 34.1772 57.0321 34.1772H22.9676C22.1934 34.1772 21.6772 33.661 21.6772 32.8868C21.6772 32.1126 22.3224 31.5965 22.9676 31.5965H56.903C57.6772 31.5965 58.3224 32.1126 58.3224 32.8868ZM58.3224 44.4997C58.3224 45.2739 57.6772 45.7901 57.0321 45.7901H22.9676C22.1934 45.7901 21.6772 45.2739 21.6772 44.4997C21.6772 43.7255 22.3224 43.2094 22.9676 43.2094H56.903C57.6772 43.2094 58.3224 43.7255 58.3224 44.4997Z"
                    fill="#A8A8A8"
                  />
                  <path
                    d="M80 27.7256L40 53.274L0 27.7256V79.3385H80V27.7256Z"
                    fill="#6965A8"
                  />
                  <path
                    d="M40 53.532V53.1449L0 27.7256V79.3385L40 53.532Z"
                    fill="#9090DD"
                  />
                  <path
                    d="M80 79.3387L40 53.5322L0 79.3387H79.7419H80Z"
                    fill="#4C4C8C"
                  />
                </g>
                <defs>
                  <clipPath>
                    <rect
                      width="80"
                      height="80"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </figure>
          </div>

          <div className="modalcontent-body">
            <h5 className="modaltitle">Check Mail</h5>
            <div className="info">
              <p>
                If there is an active account associated with the email you
                submitted, a recovery link with instructions will be emailed to
                it.
              </p>
            </div>
          </div>
        </div>
      </SignUpModal>
      {/* FORGOTPASSWORD-MODAL END */}
    </>
  );
}

export default ForgotPassword;
