import React from "react";

function SignUpModal({ visible, onClose, children }) {
  const handleOnClose = () => onClose();
  const handleModalClose = () => onClose();
  if (!visible) return null;

  return (
    <div
      onClick={handleOnClose}
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 backdropbg"></div>
      <div
        className="fixed inset-0 z-20 w-screen overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 relative modaldetail">
              {children}
              <button
                onClick={handleModalClose}
                className="absolute top-3 right-3"
              >
                <svg
                  width="33"
                  height="34"
                  viewBox="0 0 33 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.136719"
                    y="0.636719"
                    width="32.7279"
                    height="32.7279"
                    rx="16.3639"
                    fill="#616161"
                    fillOpacity="0.1"
                  />
                  <path
                    d="M16.5007 15.5865L21.4504 10.6367L22.8646 12.0509L17.9149 17.0007L22.8646 21.9504L21.4504 23.3646L16.5007 18.4149L11.5509 23.3646L10.1367 21.9504L15.0865 17.0007L10.1367 12.0509L11.5509 10.6367L16.5007 15.5865Z"
                    fill="#616161"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpModal;
