import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignUpModal from "../components/Modals/SignUpModal";
import ErrorText from "../components/Typography/ErrorText";

function SignUp() {

  const [showRegModal, setShowRegModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const showPasswordHandler = (type) => {
    if (type === 'p')
      setShowPassword(!showPassword);
    else
      setConfirmShowPassword(!showConfirmPassword);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First name is required *";
    }

    if (!formData.lastName.trim()) {
      validationErrors.lastName = "Last name is required *";
    }

    if (!formData.organization.trim()) {
      validationErrors.organization = "Organization is required *";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required *";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is not valid";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required *";
    } else if (formData.password.length < 6) {
      validationErrors.password = "Password should be at least 6 char";
    }

    if (formData.confirmPassword !== formData.password) {
      validationErrors.confirmPassword = "Password not matched";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        const response = await fetch('https://api.citiwave.io/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: formData.firstName + " " + formData.lastName,
            organization: formData.organization,
            password: formData.password,
            email: formData.email,
            confirmPassword: formData.confirmPassword,
            origin: "http://localhost:3000/"
          })
        });

        const data = await response.json();
        console.log("This is the response status: ", response.status)
        if (response.status === 200) {
          // Redirect the user to a page that only displays the success message.
          setShowRegModal(true);
          //window.location.href = `/login?status=registered`;
        } else if (response.status === 400) {
          setErrorMessage(data.message);
        } else if (response.status === 500) {
          setErrorMessage(data.message);
        } else {
          // Handle any other unexpected responses.
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      } catch (error) {
        setErrorMessage('An unexpected error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };
  const handleOnClose = () => {
    setShowRegModal(false);
    window.location.href = `/signin?status=registered`;
  };

  return (
    <>
      {/* REGISTER-START */}
      <div className="authentication">
        <figure className="auth-thumbnail">
          <img
            src="/auth-thumbnail.png"
            alt="auth-thumbnail"
            className="w-full"
          />
          <div className="absolute inset-0 z-20 h-full flex justify-center items-center overlay">
            <div className="flex flex-col gap-2 text-center">
              <h4 className="text-white text-5xl font-sans font-bold">
                Create Your Account
              </h4>

              <div className="authenticinfo">
                <p className="text-white text-2xl font-sans font-normal">
                  Join us today and get started.
                </p>
              </div>
            </div>
          </div>
        </figure>

        <div className="py-14 flex justify-center rightcontent">
          <div className="authinfo w-full">
            <div className="flex flex-col w-full">
              <div className="flex justify-center">
                <figure className="brandlogo">
                  <img src="/logo.svg" alt="brand-logo" />
                </figure>
              </div>

              <form onSubmit={handleSubmit} className="authform">
                <div className="authform-header">
                  {/* <button className="btn-auth">
                    <span className="icon">
                      <svg
                        width="27"
                        height="26"
                        viewBox="0 0 27 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1101 12.6179H1.52612V1.0332H13.1101V12.6179Z"
                          fill="#F1511B"
                        />
                        <path
                          d="M25.9009 12.6179H14.3171V1.0332H25.9009V12.6179Z"
                          fill="#80CC28"
                        />
                        <path
                          d="M13.1098 25.4127H1.52612V13.8281H13.1098V25.4127Z"
                          fill="#00ADEF"
                        />
                        <path
                          d="M25.9009 25.4127H14.3171V13.8281H25.9009V25.4127Z"
                          fill="#FBBC09"
                        />
                      </svg>
                    </span>
                    <span className="text">Sign up with Microsoft</span>
                  </button> */}

                  {/* <div className="or">
                    <figure className="divide">
                      <svg
                        width="136"
                        height="1"
                        viewBox="0 0 136 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="0.5" x2="136" y2="0.5" stroke="#EDE7F6" />
                      </svg>
                    </figure>

                    <div className="or-title">
                      <span>Or</span>
                    </div>

                    <figure className="divide">
                      <svg
                        width="136"
                        height="1"
                        viewBox="0 0 136 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="0.5" x2="136" y2="0.5" stroke="#EDE7F6" />
                      </svg>
                    </figure>
                  </div> */}
                </div>

                <div className="authform-body">
                  <h3 className="authtitle">
                    {/* Create account with email address */}
                    Create New Account
                  </h3>

                  <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
                  <div className="forminfo">
                    <div className="lg:columns-2">
                      <div className="inputbox mb-6 lg:mb-0">
                        <div className="inputinfo">
                          <label htmlFor="fname" className="inputlabel">
                            First Name
                          </label>
                          <input
                            type="text"
                            id="fname"
                            className="inputfield"
                            name="firstName"
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        {errors.firstName && (
                          <p className="errortext">{errors.firstName}</p>
                        )}
                      </div>

                      <div className="inputbox">
                        <div className="inputinfo">
                          <label htmlFor="lname" className="inputlabel">
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="lname"
                            className="inputfield"
                            name="lastName"
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        {errors.lastName && (
                          <p className="errortext">{errors.lastName}</p>
                        )}
                      </div>
                    </div>

                    <div className="inputbox">
                      <div className="inputinfo">
                        <label htmlFor="regorganization" className="inputlabel">
                          Organization Name
                        </label>
                        <input
                          type="text"
                          id="regorganization"
                          className="inputfield"
                          name="organization"
                          autoComplete="off"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.organization && (
                        <p className="errortext">{errors.organization}</p>
                      )}
                    </div>

                    <div className="inputbox">
                      <div className="inputinfo">
                        <label htmlFor="regmail" className="inputlabel">
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="regmail"
                          className="inputfield"
                          name="email"
                          autoComplete="off"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.email && (
                        <p className="errortext">{errors.email}</p>
                      )}
                    </div>

                    <div className="passwordbox">
                      <div className="passbox">
                        <div className="inputinfo">
                          <label htmlFor="regpass" className="inputlabel">
                            Password
                          </label>
                          <input type={showPassword ? 'text' : 'password'}
                            id="regpass"
                            className="inputfield"
                            name="password"
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        {errors.password && (
                          <p className="errortext">{errors.password}</p>
                        )}
                      </div>

                      <button className="btn-showhide" type='button' onClick={() => showPasswordHandler('p')}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M8.00008 3C4.66675 3 1.82008 5.07333 0.666748 8C1.82008 10.9267 4.66675 13 8.00008 13C11.3334 13 14.1801 10.9267 15.3334 8C14.1801 5.07333 11.3334 3 8.00008 3ZM8.00008 11.3333C6.16008 11.3333 4.66675 9.84 4.66675 8C4.66675 6.16 6.16008 4.66667 8.00008 4.66667C9.84008 4.66667 11.3334 6.16 11.3334 8C11.3334 9.84 9.84008 11.3333 8.00008 11.3333ZM8.00008 6C6.89341 6 6.00008 6.89333 6.00008 8C6.00008 9.10667 6.89341 10 8.00008 10C9.10675 10 10.0001 9.10667 10.0001 8C10.0001 6.89333 9.10675 6 8.00008 6Z"
                              fill="#616161"
                            />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    <div className="passwordbox">
                      <div className="passbox">
                        <div className="inputinfo">
                          <label htmlFor="confregpass" className="inputlabel">
                            Confirm Password
                          </label>
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confregpass"
                            className="inputfield"
                            name="confirmPassword"
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </div>
                        {errors.confirmPassword && (
                          <p className="errortext">{errors.confirmPassword}</p>
                        )}
                      </div>

                      <button className="btn-showhide" type='button' onClick={() => showPasswordHandler('cp')}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M8.00008 3C4.66675 3 1.82008 5.07333 0.666748 8C1.82008 10.9267 4.66675 13 8.00008 13C11.3334 13 14.1801 10.9267 15.3334 8C14.1801 5.07333 11.3334 3 8.00008 3ZM8.00008 11.3333C6.16008 11.3333 4.66675 9.84 4.66675 8C4.66675 6.16 6.16008 4.66667 8.00008 4.66667C9.84008 4.66667 11.3334 6.16 11.3334 8C11.3334 9.84 9.84008 11.3333 8.00008 11.3333ZM8.00008 6C6.89341 6 6.00008 6.89333 6.00008 8C6.00008 9.10667 6.89341 10 8.00008 10C9.10675 10 10.0001 9.10667 10.0001 8C10.0001 6.89333 9.10675 6 8.00008 6Z"
                              fill="#616161"
                            />
                          </g>
                          <defs>
                            <clipPath>
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    {/* <div className="customcheckbox">
                      <input
                        type="checkbox"
                        id="agreereg"
                        className="remembercheck"
                        hidden
                      />
                      <label htmlFor="agreereg" className="customcheck">
                        Agree with{" "}
                        <a href="#" className="forgotlink">
                          Terms & Condition.
                        </a>
                      </label>
                    </div> */}

                    <div className="submitbox">
                      <button className="btn-submit" type="submit" disabled={loading}>
                        {loading ? 'loading...' : 'Create Account'}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="authform-footer">
                  <p className="textauth">
                    Already have an account?
                    <Link to="/login" className="forgotlink">
                      Sign in
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* REGISTER-END */}

      {/* SIGNUP-MODAL START */}
      <SignUpModal onClose={handleOnClose} visible={showRegModal}>
        <div className="modalcontent">
          <div className="modalcontent-header">
            <figure className="modalicon">
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                    fill="#32BA7C"
                  />
                  <path
                    d="M29.7798 58.044L50.3476 78.6118C67.3823 74.0692 80.0006 58.5487 80.0006 39.9998V38.8642L63.8492 23.9746L29.7798 58.044Z"
                    fill="#0AA06E"
                  />
                  <path
                    d="M41.0099 48.9588C42.7765 50.7253 42.7765 53.7537 41.0099 55.5203L37.3506 59.1796C35.5841 60.9461 32.5557 60.9461 30.7891 59.1796L14.7639 43.0282C12.9973 41.2616 12.9973 38.2332 14.7639 36.4666L18.4232 32.8073C20.1898 31.0408 23.2181 31.0408 24.9847 32.8073L41.0099 48.9588Z"
                    fill="white"
                  />
                  <path
                    d="M55.0163 21.0725C56.7829 19.3059 59.8113 19.3059 61.5778 21.0725L65.2371 24.7318C67.0037 26.4983 67.0037 29.5267 65.2371 31.2933L37.4769 58.9274C35.7103 60.6939 32.6819 60.6939 30.9154 58.9274L27.2561 55.2681C25.4895 53.5015 25.4895 50.4731 27.2561 48.7065L55.0163 21.0725Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath>
                    <rect width="80" height="80" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </figure>
          </div>

          <div className="modalcontent-body">
            <h5 className="modaltitle">Signup Successfully</h5>
            <div className="info">
              <p>
                To activate your account, please check your email inbox for an
                account verification email and click the account verification
                link.{" "}
                <a href={"mailto:" + formData.email} className="forgotlink">
                  Go to mail
                </a>
              </p>
            </div>
          </div>
        </div>
      </SignUpModal>
      {/* SIGNUP-MODAL END */}
    </>
  );
}

export default SignUp;
