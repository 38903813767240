import { PublicClientApplication } from "@azure/msal-browser";

const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || window.location.origin;
console.log("This is the redirect uri: ", REDIRECT_URI)
console.log("This is the redirect uri variable: ", process.env.REDIRECT_URI)
console.log("This is the react redirect uri variable: ", process.env.REACT_APP_REDIRECT_URI)


export const msalConfig = {
    auth: {
        clientId: "eca89812-7458-490a-a5c1-e174cdb28663",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);
